.lux-modal-root {
  // Create a stacking context so that the modal is in front of everything.
  // This should be placed at the root of the application and only needs to be applied to one div.
  position: relative;
  z-index: 1;
}

.lux-modal-header {
  padding: var(--modal-header-footer-padding);
  background-color: var(--modal-header-bg-color);
  border-bottom: 1px solid var(--divider-color);

  .title {
    color: var(--primary-color);
    font-size: 1.1rem;
    font-weight: var(--font-weight-bold);
  }

  .close {
    padding: 0;
    border: 0;
    border-radius: 100px;
    background-color: var(--secondary-color);
    color: var(--modal-bg-color);
    width: 1.25rem;
    height: 1.25rem;

    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 450px) {
    background-color: var(--modal-header-bg-color);
  }
}

.lux-modal {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  width: 480px;
  max-height: 70vh;

  border-radius: var(--modal-border-radius);
  box-shadow: var(--shadow-modal);
  background-color: var(--modal-bg-color);
  backdrop-filter: var(--backdrop-blur);

  &.lux-alert {
    width: 340px;
  }

  &.big {
    border: 1px solid var(--divider-color);
    border-radius: var(--large-border-radius);
    box-shadow: var(--shadow);
    background-color: var(--modal-bg-color);
    width: calc(min(90vw, 800px));
    backdrop-filter: var(--backdrop-blur);
    height: 80vh;
    max-height: min(80vh, 1000px);

    @media (max-width: 650px) {
      height: 100vh;
      max-height: 100vh;
    }
  }

  &.panel {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    border-radius: var(--border-radius);
    max-height: calc(100vh - 1rem);
    max-height: calc(100dvh - 1rem);
    height: calc(100vh - 1rem);
    height: calc(100dvh - 1rem);
    width: 550px;
  }

  @media (max-width: 650px) {
    &:not(.lux-alert) {
      width: calc(100% - 40px);
      max-width: 480px;
    }

    &.panel {
      width: calc(450px - 1rem);
    }

    &.big {
      width: 100%;
      height: 100%;
      border-radius: 0;
      max-width: 100%;
      max-height: 100%;
    }
  }

  @media (max-width: 450px) {
    &:not(.compact):not(.lux-alert) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      max-width: 100%;
      max-height: 100%;
    }

    &.panel {
      margin: 0;
      max-height: 100vh;
      max-height: 100dvh;
      height: 100vh;
      height: 100dvh;
    }

    &.lux-alert {
      width: calc(100% - 40px);
      max-width: 340px;
      margin-bottom: 15vh;
    }
  }

  .lux-modal-body {
    width: 100%;
    padding: var(--modal-padding);
  }

  &.panel .lux-modal-body {
    flex: 1;
  }

  .lux-modal-footer {
    border-top: 1px solid var(--divider-color);
    background-color: var(--primary-bg-color);
    width: 100%;
    padding: var(--modal-header-footer-padding);
  }

  --rich-button-border-color: var(--opacity-light);
}

.theme-root.dark,
.theme-root.dark .tint-root,
.theme-root .tint-root.dark {
  .lux-modal {
    --rich-button-bg-color: rgba(40, 42, 43, 0.8);
  }
}

.lux-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color);
  z-index: 1000;
  backdrop-filter: grayscale(0.3);

  &.glass {
    background-color: var(--primary-bg-color-translucent);
    backdrop-filter: var(--high-legibility-backdrop-blur);
  }
}

.lux-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 15vh;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: var(--overlay-z-index);

  --overlay-content-padding: 0;

  &.panel {
    padding-top: 0;
    justify-content: end;
  }

  &.glass {
    padding-top: 0;
    align-items: center;
  }

  &.big {
    padding-top: 5vh;

    @media (max-width: 650px) {
      padding-top: 0;
    }
  }

  @keyframes pop {
    50% {
      transform-origin: center;
      transform: scale(1.005);
    }
  }

  &.pop .lux-modal {
    animation: pop 0.3s cubic-bezier(0.4, 0, 0.2, 1) 1;
  }

  @media (max-width: 650px) {
    &.cmdk {
      padding: 2.5rem;
      align-items: start;
    }

    &.glass {
      padding: 0;
      align-items: start;
    }
  }

  @media (max-width: 450px) {
    padding-top: 0;
    align-items: center;

    &.cmdk {
      padding: 1.25rem;
    }
  }
}
