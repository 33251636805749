.theme-root,
.tint-root,
.theme-root.dark .tint-root.light {
  // Base page colors
  --page-bg-color: var(--secondary-bg-color); // overall page background
  --page-bg-color-translucent: var(--secondary-bg-color-translucent);

  --card-bg-color: var(--white-opacity-80); // card (elevated) background
  --card-border-color: white;
  --card-hover-bg-color: var(--black-opacity-16);
  --card-hover-shadow: 0px 28px 17px rgba(0, 0, 0, 0.005),
    0px 12px 12px rgba(0, 0, 0, 0.01), 0px 3px 7px rgba(0, 0, 0, 0.01),
    0px 0px 0px rgba(0, 0, 0, 0.01);
  --table-border-color: var(--divider-color);
  --rich-button-bg-color: var(--card-bg-color);
  --rich-button-border-color: var(--card-border-color);

  // Top nav bar
  --nav-item-color: var(--primary-color);
  --nav-item-hover-color: var(--primary-color);
  --nav-item-hover-bg-color: var(--tertiary-bg-color);
  --nav-item-selected-color: var(--brand-color);

  --nav-link-color: var(--tertiary-color-alpha);
  --nav-link-hover-color: var(--primary-color);

  --nav-vertical-padding: 0.75rem;
  --nav-horizontal-padding: 1rem;
  --nav-padding: var(--nav-vertical-padding) var(--nav-horizontal-padding);

  --page-header-tab-color: var(--tertiary-color-alpha);
  --page-header-tab-color-hover: var(--secondary-color-alpha);
  --page-header-tab-color-selected: var(--primary-color);

  --tab-item-color: var(--secondary-color);
  --tab-item-hover-color: var(--primary-color);
  --tab-item-selected-color: var(--brand-color);
  --tabbar-side-offset: 1rem;
}

.theme-root.dark,
.theme-root.dark .tint-root,
.theme-root .tint-root.dark {
  --page-bg-color: var(--primary-bg-color);
  --page-bg-color-translucent: var(--primary-bg-color-translucent);

  --card-bg-color: var(--white-opacity-4);
  --card-border-color: var(--white-opacity-4);
  --card-hover-bg-color: var(--white-opacity-16);
  --card-hover-shadow: 0px 28px 17px rgba(0, 0, 0, 0.01),
    0px 12px 12px rgba(0, 0, 0, 0.02), 0px 3px 7px rgba(0, 0, 0, 0.02),
    0px 0px 0px rgba(0, 0, 0, 0.02);
  --rich-button-bg-color: rgba(34, 36, 37, 0.8);
  --rich-button-border-color: var(--white-opacity-8);
}
