.event-theme {
  --page-bg: var(--depressed-secondary-bg-color);
  --translucent-overlay-bg-color: var(--elevated-secondary-bg-color);
  --card-bg-color: var(--elevated-primary-bg-color);
  --card-bg-color-opaque: var(--card-bg-color);
  --card-backdrop-filter: none;
  --card-shadow: none;

  &:not(.tinted) {
    --tint-color: var(--secondary-color-alpha);
  }

  .text-tinted {
    color: var(--tint-color);
  }
}

.theme-root .event-theme.tinted {
  --page-bg: var(--brand-10, var(--depressed-secondary-bg-color));
  --primary-button-bg-color: var(--brand-50, var(--gray-90));
  --primary-button-hover-bg-color: var(--brand-40, var(--gray-80));
}

.theme-root.dark .event-theme.tinted {
  --page-bg: var(--brand-80, var(--depressed-secondary-bg-color));
  --card-bg-color: var(--brand-70, var(--elevated-primary-bg-color));

  --primary-button-bg-color: var(--white);
  --primary-button-hover-bg-color: var(--gray-20);
  --primary-bg-color: var(--brand-80);
}

.theme-root .event-theme.nebula {
  --page-bg: url("https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,quality=75,width=353,height=303/misc/0n/b4874879-87dd-44a4-9ae7-32176a8122d9");
  --card-bg-color: #fffb;
  --card-bg-color-opaque: #fff;
  --card-backdrop-filter: blur(16px);
}

.theme-root .event-theme.pattern,
.theme-root.dark .event-theme.pattern {
  --card-bg-color: var(--bg-opacity-64);
  --card-backdrop-filter: var(--backdrop-blur);
  --card-shadow: 0px -4px 15px 0px rgba(255, 255, 255, 0.5) inset;

  --lighter-color: var(--brand-10, var(--depressed-secondary-bg-color));
  --darker-color: var(--brand-20, var(--gray-20));

  @media (max-width: 650px) {
    --card-backdrop-filter: blur(2px);
  }
}

.theme-root .event-theme.shader {
  --card-bg-color: var(--bg-opacity-64);
  --card-backdrop-filter: var(--backdrop-blur);
  --card-shadow: 0px -4px 15px 0px rgba(255, 255, 255, 0.5) inset;
}

.tint-root.dark .event-theme.shader {
  --card-bg-color: var(--bg-opacity-48);
  --card-backdrop-filter: var(--backdrop-blur);
  --card-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.1) inset;
}

figure.preview .event-theme {
  // The backdrop blur will cause the preview to overflow the border radius.
  --card-backdrop-filter: none !important;

  &.pattern {
    --card-bg-color: white !important;
  }

  .background {
    height: 100%;
  }
}

.theme-root .event-theme.zigzag .background, .pattern-zigzag {
  background-image: linear-gradient(
    135deg,
    var(--lighter-color) 25%,
    transparent 25%
  ),
  linear-gradient(225deg, var(--lighter-color) 25%, transparent 25%),
  linear-gradient(45deg, var(--lighter-color) 25%, transparent 25%),
  linear-gradient(315deg, var(--lighter-color) 25%, var(--darker-color) 25%);
  background-position: 32px 0, 32px 0, 0 0, 0 0;
  background-size: 64px 64px;
  background-repeat: repeat;
}

.theme-root .event-theme.plus .background, .pattern-plus {
  background-color: var(--lighter-color);
  background: radial-gradient(
      circle,
      transparent 20%,
      var(--lighter-color) 20%,
      var(--lighter-color) 80%,
      transparent 80%,
      transparent
    ),
    radial-gradient(
        circle,
        transparent 20%,
        var(--lighter-color) 20%,
        var(--lighter-color) 80%,
        transparent 80%,
        transparent
      )
      50px 50px,
    linear-gradient(var(--darker-color) 4px, transparent 4px) 0 -2px,
    linear-gradient(90deg, var(--darker-color) 4px, var(--lighter-color) 4px) -2px 0;
  background-size: 100px 100px, 100px 100px, 50px 50px, 50px 50px;
}

.theme-root .event-theme.wave .background, .pattern-wave {
  background: radial-gradient(
    circle at top left,
    transparent 25%,
    var(--darker-color) 25.5%,
    var(--darker-color) 36%,
    transparent 37%,
    transparent 100%
  ),
  radial-gradient(
    circle at bottom right,
    transparent 34%,
    var(--darker-color) 34.5%,
    var(--darker-color) 45.5%,
    transparent 46%,
    transparent 100%
  );
  background-size: 50px 50px;
  background-color: var(--lighter-color);
}

.theme-root .event-theme.polkadot .background, .pattern-polkadot {
  background: radial-gradient(circle, var(--darker-color) 10%, transparent 12%),
    radial-gradient(circle at bottom left, var(--darker-color) 5%, transparent 7%),
    radial-gradient(circle at bottom right, var(--darker-color) 5%, transparent 7%),
    radial-gradient(circle at top left, var(--darker-color) 5%, transparent 7%),
    radial-gradient(circle at top right, var(--darker-color) 5%, transparent 7%);
  background-size: 50px 50px;
  background-color: var(--lighter-color);
}

.theme-root .event-theme.cross .background, .pattern-cross {
  background: repeating-linear-gradient(
      transparent,
      transparent 8px,
      var(--lighter-color) 8px,
      var(--lighter-color) 12px,
      transparent 12px,
      transparent 16px,
      var(--lighter-color) 12px,
      var(--lighter-color) 32px,
      transparent 32px,
      transparent 36px,
      var(--lighter-color) 36px,
      var(--lighter-color) 40px,
      transparent 40px,
      transparent 64px
    ),
    repeating-linear-gradient(
      90deg,
      transparent,
      transparent 8px,
      var(--lighter-color) 8px,
      var(--lighter-color) 12px,
      transparent 12px,
      transparent 16px,
      var(--lighter-color) 12px,
      var(--lighter-color) 32px,
      transparent 32px,
      transparent 36px,
      var(--lighter-color) 36px,
      var(--lighter-color) 40px,
      transparent 40px,
      transparent 64px
    ),
    var(--lighter-color);
  background-blend-mode: multiply;
  background-color: var(--lighter-color);
}

.theme-root .event-theme.hypnotic .background, .pattern-hypnotic {
  background: radial-gradient(
    50% 50% at 100% 0,
    var(--darker-color) 0% 5%,
    var(--lighter-color) 6% 15%,
    var(--darker-color) 16% 25%,
    var(--lighter-color) 26% 35%,
    var(--darker-color) 36% 45%,
    var(--lighter-color) 46% 55%,
    var(--darker-color) 56% 65%,
    var(--lighter-color) 66% 75%,
    var(--darker-color) 76% 85%,
    var(--lighter-color) 86% 95%,
    transparent 96%
  ),
  radial-gradient(
    50% 50% at 0 100%,
    var(--darker-color) 0% 5%,
    var(--lighter-color) 6% 15%,
    var(--darker-color) 16% 25%,
    var(--lighter-color) 26% 35%,
    var(--darker-color) 36% 45%,
    var(--lighter-color) 46% 55%,
    var(--darker-color) 56% 65%,
    var(--lighter-color) 66% 75%,
    var(--darker-color) 76% 85%,
    var(--lighter-color) 86% 95%,
    transparent 96%
  ),
  radial-gradient(
    50% 50%,
    var(--darker-color) 0% 5%,
    var(--lighter-color) 6% 15%,
    var(--darker-color) 16% 25%,
    var(--lighter-color) 26% 35%,
    var(--darker-color) 36% 45%,
    var(--lighter-color) 46% 55%,
    var(--darker-color) 56% 65%,
    var(--lighter-color) 66% 75%,
    var(--darker-color) 76% 85%,
    var(--lighter-color) 86% 95%,
    transparent 96%
  ),
  radial-gradient(
      50% 50%,
      var(--darker-color) 0% 5%,
      var(--lighter-color) 6% 15%,
      var(--darker-color) 16% 25%,
      var(--lighter-color) 26% 35%,
      var(--darker-color) 36% 45%,
      var(--lighter-color) 46% 55%,
      var(--darker-color) 56% 65%,
      var(--lighter-color) 66% 75%,
      var(--darker-color) 76% 85%,
      var(--lighter-color) 86% 95%,
      transparent 96%
    )
    32px 32px;
  background-size: 64px 64px;
  background-color: var(--lighter-color);
}
