.theme-root,
.tint-root {
  --white: #ffffff;
  --gray-10: #f4f5f6;
  --gray-20: #ebeced;
  --gray-30: #dee0e2;
  --gray-40: #d2d4d7;
  --gray-50: #b3b5b7;
  --gray-60: #939597;
  --gray-70: #737577;
  --gray-80: #535557;
  --gray-90: #333537;
  --gray-100: #212325;
  --gray-100-base-rgb: 33, 35, 37;
  --black-base-rgb: 19, 21, 23;
  --black: rgb(var(--black-base-rgb));
  --pure-black: #000000;
  --white-transparent: #ffffff00;
  --gray-10-transparent: #f4f5f600;
  --gray-20-transparent: #ebeced00;
  --gray-30-transparent: #dee0e200;
  --gray-40-transparent: #d2d4d700;
  --gray-50-transparent: #b3b5b700;
  --gray-80-transparent: #53555700;
  --gray-90-transparent: #33353700;
  --gray-100-transparent: #21232500;
  --black-transparent: rgba(var(--black-base-rgb), 0);
  --gray-10-thick-translucent: #f4f5f6cc;
  --gray-20-translucent: #eaecee40;
  --gray-100-thick-translucent: #212325cc;
  --white-translucent: #ffffff40;
  --white-thick-translucent: #fffd;
  --black-translucent: rgba(var(--black-base-rgb), 0.25);
  --black-thick-translucent: rgba(var(--black-base-rgb), 0.8);
  --darker-pale-gray: #93959744;
  --pale-gray: #93959722;
  --faint-gray: #93959711;
  --pale-white: #fff2;

  --half-white: #fff9;
  --half-gray: #b3b5b799;
  --half-black: #0009;

  --cranberry-10: #fbe5eb;
  --cranberry-20: #f7cbd8;
  --cranberry-30: #ee98b1;
  --cranberry-40: #e6658a;
  --cranberry-50: #de3163;
  --cranberry-60: #c22b57;
  --cranberry-70: #8b1f3e;
  --cranberry-80: #531225;
  --pale-cranberry: #de316322;
  --faint-cranberry: #de316311;

  --barney-10: #f6e5f8;
  --barney-20: #eecaf1;
  --barney-30: #dd96e3;
  --barney-40: #cc62d5;
  --barney-50: #bb2dc7;
  --barney-60: #a427ae;
  --barney-70: #751c7c;
  --barney-80: #46114b;
  --pale-barney: #bb2dc722;
  --faint-barney: #bb2dc711;

  --purple-10: #e3defd;
  --purple-20: #d4c4ff;
  --purple-30: #b596ff;
  --purple-40: #7b49ff;
  --purple-50: #682fff;
  --purple-60: #5b29df;
  --purple-70: #4b23bf;
  --purple-80: #2f1880;
  --pale-purple: #682fff22;
  --faint-purple: #682fff11;

  --blue-10: #d4e5ff;
  --blue-20: #b6d3ff;
  --blue-30: #76adff;
  --blue-40: #3787ff;
  --blue-50: #325ffa;
  --blue-60: #2c53db;
  --blue-70: #1f3b9c;
  --blue-80: #13245e;
  --pale-blue: #325ffa22;
  --faint-blue: #325ffa11;
  --transparent-blue: #325ffa00;

  --green-10: #d4f2e1;
  --green-20: #a3e4bf;
  --green-30: #75d79e;
  --green-40: #47c97e;
  --green-50: #07a460;
  --green-60: #078c53;
  --green-70: #067847;
  --green-80: #045030;
  --darker-pale-green: #07a46044;
  --pale-green: #07a46022;
  --faint-green: #07a46011;

  --yellow-10: #fff2cc;
  --yellow-20: #ffecb2;
  --yellow-30: #fde38f;
  --yellow-40: #fbd85b;
  --yellow-50: #facc28;
  --yellow-60: #d19d20;
  --yellow-70: #a56915;
  --yellow-80: #7f3e11;
  --pale-yellow: #d19d2022;
  --faint-yellow: #d19d2011;

  --orange-10: #ffecdb;
  --orange-20: #ffcba0;
  --orange-30: #ffb071;
  --orange-40: #ff9641;
  --orange-50: #ec660d;
  --orange-60: #cf590b;
  --orange-70: #b14d0a;
  --orange-80: #763307;
  --pale-orange: #ec660d22;
  --faint-orange: #ec660d11;

  --red-10: #ffe5e7;
  --red-20: #ffc1c5;
  --red-30: #ff838c;
  --red-40: #ff5965;
  --red-50: #e83b47;
  --red-60: #cb343e;
  --red-70: #98272f;
  --red-80: #5b171b;
  --pale-red: #e83b4722;
  --faint-red: #e83b4711;
  --half-red: #e83b4777;

  --calendar-red: #d86156;

  --black-opacity-0: rgba(var(--black-base-rgb), 0);
  --black-opacity-4: rgba(var(--black-base-rgb), 0.04);
  --black-opacity-8: rgba(var(--black-base-rgb), 0.08);
  --black-opacity-16: rgba(var(--black-base-rgb), 0.16);
  --black-opacity-24: rgba(var(--black-base-rgb), 0.24);
  --black-opacity-32: rgba(var(--black-base-rgb), 0.32);
  --black-opacity-48: rgba(var(--black-base-rgb), 0.48);
  --black-opacity-64: rgba(var(--black-base-rgb), 0.64);
  --black-opacity-80: rgba(var(--black-base-rgb), 0.8);

  --white-base-rgb: 255, 255, 255;
  --white-opacity-0: rgba(var(--white-base-rgb), 0);
  --white-opacity-4: rgba(var(--white-base-rgb), 0.04);
  --white-opacity-8: rgba(var(--white-base-rgb), 0.08);
  --white-opacity-16: rgba(var(--white-base-rgb), 0.16);
  --white-opacity-24: rgba(var(--white-base-rgb), 0.24);
  --white-opacity-32: rgba(var(--white-base-rgb), 0.32);
  --white-opacity-48: rgba(var(--white-base-rgb), 0.48);
  --white-opacity-64: rgba(var(--white-base-rgb), 0.64);
  --white-opacity-80: rgba(var(--white-base-rgb), 0.8);

  --default-gradient: linear-gradient(
    -45deg,
    #8a18a8 0%,
    #ce2756 51.59%,
    #cf2a55 51.6%,
    #e7a90d 100.05%
  );

  --pure-black-or-white: var(--pure-black);

  --outline-color: -webkit-focus-ring-color;
}

.theme-root,
.tint-root,
.theme-root.dark .tint-root.light {
  // `background` and `background-active` are intended to be used with white text. They do not change colors
  // on light / dark since they create a dark mode background on their own.

  --gray: var(--gray-60);
  --gray-active: var(--gray-70);
  --gray-background: var(--gray-60);
  --gray-background-active: var(--gray-70);

  --barney: var(--barney-50);
  --barney-active: var(--barney-60);
  --barney-background: var(--barney-50);
  --barney-background-active: var(--barney-60);

  --blue: var(--blue-50);
  --blue-active: var(--blue-60);
  --blue-background: var(--blue-50);
  --blue-background-active: var(--blue-60);

  --cranberry: var(--cranberry-50);
  --cranberry-active: var(--cranberry-60);
  --cranberry-background: var(--cranberry-50);
  --cranberry-background-active: var(--cranberry-60);

  --green: var(--green-50);
  --green-active: var(--green-60);
  --green-background: var(--green-50);
  --green-background-active: var(--green-60);

  --orange: var(--orange-50);
  --orange-active: var(--orange-60);
  --orange-background: var(--orange-50);
  --orange-background-active: var(--orange-60);

  --purple: var(--purple-50);
  --purple-active: var(--purple-60);
  --purple-background: var(--purple-50);
  --purple-background-active: var(--purple-60);

  --red: var(--red-50);
  --red-active: var(--red-60);
  --red-background: var(--red-50);
  --red-background-active: var(--red-60);

  --yellow: var(--yellow-60);
  --yellow-active: var(--yellow-70);
  --yellow-background: var(--yellow-60);
  --yellow-background-active: var(--yellow-70);

  --brand-color: var(--cranberry);
  --brand-active-color: var(--cranberry-active);
  --brand-pale-bg-color: var(--pale-cranberry);

  --success-color: var(--green-50);
  --success-active-color: var(--green-60);
  --success-pale-bg-color: var(--pale-green);
  --error-color: var(--red-50);
  --error-active-color: var(--red-60);
  --error-pale-bg-color: var(--pale-red);
  --warning-color: var(--yellow-60);
  --warning-active-color: var(--yellow-70);
  --warning-pale-bg-color: var(--pale-yellow);

  --primary-color: var(--black);
  --primary-color-rgb: var(--black-base-rgb);
  --secondary-color: var(--gray-70);
  // The -alpha colors are transparent primary colors.
  // They should look the same as their non-alpha counterpart on the default
  // background.
  --secondary-color-alpha: rgba(var(--black-base-rgb), 0.64);
  --tertiary-color: var(--gray-50);
  --tertiary-color-alpha: rgba(var(--black-base-rgb), 0.36);
  --quaternary-color: var(--gray-30);
  --quaternary-color-alpha: rgba(var(--black-base-rgb), 0.2);

  --primary-or-tinted-color: var(--primary-color);

  --primary-bg-color: var(--white);
  --primary-bg-color-rgb: var(--white-base-rgb);
  --primary-bg-color-translucent: var(--white-opacity-64);
  --primary-bg-color-thick-translucent: var(--white-thick-translucent);
  --primary-bg-color-transparent: var(--white-transparent);
  --secondary-bg-color: var(--gray-10);
  --secondary-bg-color-translucent: var(--gray-10-thick-translucent);
  --secondary-bg-color-transparent: var(--gray-10-transparent);
  --tertiary-bg-color: var(--gray-20);
  --quaternary-bg-color: var(--gray-30);
  --elevated-on-secondary-bg-color: var(--primary-bg-color);

  --depressed-secondary-bg-color: var(--secondary-bg-color);
  --elevated-primary-bg-color: var(--primary-bg-color);
  --elevated-secondary-bg-color: var(--secondary-bg-color);
  --elevated-tertiary-bg-color: var(--tertiary-bg-color);

  --nav-bg-color: transparent;

  --translucent-bg-color: #fff8;
  --slight-translucent-bg-color: #fffd;
  --slight-translucent-elevated-bg-color: #fffd;

  --primary-border-color: var(--gray-40);
  --secondary-border-color: var(--gray-20);
  --active-border-color: var(--black);

  --opacity-0: var(--black-opacity-0);
  --opacity-4: var(--black-opacity-4);
  --opacity-8: var(--black-opacity-8);
  --opacity-16: var(--black-opacity-16);
  --opacity-24: var(--black-opacity-24);
  --opacity-32: var(--black-opacity-32);
  --opacity-48: var(--black-opacity-48);
  --opacity-64: var(--black-opacity-64);
  --opacity-80: var(--black-opacity-80);
  --opacity-light: var(--black-opacity-4);
  --opacity-second-light: var(--black-opacity-8);

  --bg-opacity-0: var(--white-opacity-0);
  --bg-opacity-4: var(--white-opacity-4);
  --bg-opacity-8: var(--white-opacity-8);
  --bg-opacity-16: var(--white-opacity-16);
  --bg-opacity-24: var(--white-opacity-24);
  --bg-opacity-32: var(--white-opacity-32);
  --bg-opacity-48: var(--white-opacity-48);
  --bg-opacity-64: var(--white-opacity-64);
  --bg-opacity-80: var(--white-opacity-80);
  --bg-opacity-light: var(--white-opacity-4);

  --primary-divider-color: var(--gray-90);
  --secondary-divider-color: var(--gray-50);
  --tertiary-divider-color: var(--opacity-8);
  --primary-divider-color-transparent: var(--gray-90-transparent);
  --secondary-divider-color-transparent: var(--gray-50-transparent);
  --tertiary-divider-color-transparent: var(--opacity-0);

  --overlay-color: var(--half-gray);

  --divider-color: var(--tertiary-divider-color);
  --gradient-divider: linear-gradient(
    to right,
    var(--tertiary-divider-color-transparent),
    var(--tertiary-divider-color) 50%,
    var(--tertiary-divider-color-transparent)
  );

  --hover-bg-color: var(--pale-gray);
  --disabled-bg-color: var(--gray-20);
  --disabled-bg-color-translucent: var(--gray-20-translucent);

  --toast-default-color: var(--primary-bg-color);

  // used by value in favicon.ts when rendering red dot on the favicon
  --unread-badge-bg-color: var(--red-50);

  // Used on the community map
  --map-tooltip-shadow: 0 6px 16px rgba(30, 53, 77, 0.5);
  --map-tooltip-bg-color: #fff9;

  // Used on the lu.ma/home dashboard
  --banner-color: var(--secondary-color);
  --banner-bg-color: var(--opacity-light);
  --banner-border-color: var(--divider-color);

  // Used for event calendar cards
  --event-calendar-border-color: var(--tertiary-bg-color);
  --event-calendar-bg-color: var(--primary-bg-color);
  --event-calendar-month-color: var(--tertiary-color);
  --event-calendar-month-bg-color: var(--tertiary-bg-color);
}

.theme-root.dark,
.theme-root.dark .tint-root,
.theme-root .tint-root.dark {
  --gray: var(--gray-40);
  --gray-active: var(--gray-50);

  --barney: var(--barney-40);
  --barney-active: var(--barney-50);

  --blue: var(--blue-40);
  --blue-active: var(--blue-50);

  --cranberry: var(--cranberry-40);
  --cranberry-active: var(--cranberry-50);

  --green: var(--green-40);
  --green-active: var(--green-50);

  --orange: var(--orange-40);
  --orange-active: var(--orange-50);

  --purple: var(--purple-30);
  --purple-active: var(--purple-40);

  --red: var(--red-40);
  --red-active: var(--red-50);

  --yellow: var(--yellow-40);
  --yellow-active: var(--yellow-50);

  --brand-color: var(--cranberry);
  --brand-active-color: var(--cranberry-active);

  --default-gradient: linear-gradient(
    -45deg,
    #d118ff 0%,
    #f32861 51.59%,
    #f8245d 51.6%,
    #ffbe19 100.05%
  );

  --success-color: var(--green-50);
  --success-active-color: var(--green-60);
  --error-color: var(--red-50);
  --error-active-color: var(--red-60);
  --warning-color: var(--yellow-50);
  --warning-active-color: var(--yellow-60);

  --primary-color: var(--white);
  --primary-color-rgb: var(--white-base-rgb);
  --secondary-color: var(--gray-40);
  --secondary-color-alpha: rgba(255, 255, 255, 0.79);
  --tertiary-color: var(--gray-60);
  --tertiary-color-alpha: rgba(255, 255, 255, 0.5);
  --quaternary-color: var(--gray-80);
  --quaternary-color-alpha: rgba(255, 255, 255, 0.32);

  --primary-bg-color: var(--black);
  --primary-bg-color-rgb: var(--black-base-rgb);
  --primary-bg-color-thick-translucent: var(--black-thick-translucent);
  --primary-bg-color-translucent: var(--black-opacity-64);
  --primary-bg-color-transparent: var(--black-transparent);
  --secondary-bg-color: var(--gray-100);
  --secondary-bg-color-translucent: var(--gray-100-thick-translucent);
  --secondary-bg-color-transparent: var(--gray-100-transparent);
  --tertiary-bg-color: var(--gray-90);
  --quaternary-bg-color: var(--gray-80);
  --elevated-on-secondary-bg-color: var(--opacity-4);

  --depressed-secondary-bg-color: var(--primary-bg-color);
  --elevated-primary-bg-color: var(--secondary-bg-color);
  --elevated-secondary-bg-color: var(--tertiary-bg-color);
  --elevated-tertiary-bg-color: var(--quaternary-bg-color);

  --translucent-bg-color: rgba(var(--black-base-rgb), 0.53);
  --slight-translucent-bg-color: rgba(var(--black-base-rgb), 0.8);
  --slight-translucent-elevated-bg-color: rgba(var(--gray-100-base-rgb), 0.8);

  --opacity-0: var(--white-opacity-0);
  --opacity-4: var(--white-opacity-4);
  --opacity-8: var(--white-opacity-8);
  --opacity-16: var(--white-opacity-16);
  --opacity-24: var(--white-opacity-24);
  --opacity-32: var(--white-opacity-32);
  --opacity-48: var(--white-opacity-48);
  --opacity-64: var(--white-opacity-64);
  --opacity-80: var(--white-opacity-80);
  --opacity-light: var(--white-opacity-8);
  --opacity-second-light: var(--white-opacity-16);

  --bg-opacity-0: var(--black-opacity-0);
  --bg-opacity-4: var(--black-opacity-4);
  --bg-opacity-8: var(--black-opacity-8);
  --bg-opacity-16: var(--black-opacity-16);
  --bg-opacity-24: var(--black-opacity-24);
  --bg-opacity-32: var(--black-opacity-32);
  --bg-opacity-48: var(--black-opacity-48);
  --bg-opacity-64: var(--black-opacity-64);
  --bg-opacity-80: var(--black-opacity-80);
  --bg-opacity-light: var(--black-opacity-8);

  --primary-border-color: var(--gray-60);
  --secondary-border-color: var(--gray-90);
  --active-border-color: var(--white);

  --primary-divider-color: var(--gray-10);
  --secondary-divider-color: var(--gray-60);
  --tertiary-divider-color: var(--opacity-8);
  --primary-divider-color-transparent: var(--gray-10-transparent);
  --secondary-divider-color-transparent: var(--gray-60-transparent);
  --tertiary-divider-color-transparent: var(--gray-100-transparent);

  --overlay-color: var(--half-black);
  --divider-color: var(--tertiary-divider-color);

  --hover-bg-color: var(--pale-gray);
  --disabled-bg-color: var(--gray-80);
  --disabled-bg-color-translucent: var(--gray-80-translucent);

  --pure-black-or-white: var(--white);

  --unread-badge-bg-color: var(--red-40);

  --map-tooltip-shadow: 0 6px 16px rgba(0, 0, 0, 0.5);
  --map-tooltip-bg-color: #0009;
}

$colors: (
  "green": "green",
  "barney": "barney",
  "red": "red",
  "cranberry": "cranberry",
  "yellow": "yellow",
  "orange": "orange",
  "purple": "purple",
  "blue": "blue",
  "warning": "yellow",
  "error": "red",
  "success": "green",
  "gray": "gray",
);

/**
 * Generates something lke:
 *
 * .variant-color-red {
 *   --variant-color: var(--red);
 *   --variant-color-pale: var(--pale-red);
 *   --variant-color-active: var(--red-active);
 *   --variant-color-bg: var(--red-background);
 * }
 */
@each $name, $var in $colors {
  .variant-color-#{$name} {
    --variant-color: var(--#{$var});
    --variant-color-pale: var(--pale-#{$var});
    --variant-color-active: var(--#{$var}-active);
    --variant-color-bg: var(--#{$var}-background);
    --variant-color-bg-active: var(--#{$var}-background-active);
  }
}
