// ALL our styles, except for variables, MUST be below bootstrap.
@import "variables";

@import "color";
@import "brand_color";

@import "base";
@import "utils";
@import "layout";
@import "typography";
@import "svg";

@import "animation";
@import "button";
@import "chat";
@import "controls";
@import "overlay";
@import "effects";
@import "shimmer";
@import "editor";
@import "table";
@import "responsive";

@import "input_button";
@import "text";
@import "react_select";
@import "dropzone";

@import "page";
@import "section";
@import "timeline";
@import "simple_drop_zone";
@import "prosemirror";

@import "lux_button_switcher";
@import "lux_button";
@import "lux_checkbox";
@import "lux_code_block";
@import "lux_empty_state";
@import "lux_input_label";
@import "lux_input_wrapper";
@import "lux_input";
@import "lux_menu";
@import "lux_naked_input";
@import "lux_overlay";
@import "lux_radio_input";
@import "lux_spinner";
@import "lux_toggle";

@import "event_themes";

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  scrollbar-gutter: stable;
}

.theme-root.light {
  color-scheme: light;
}

.theme-root.dark {
  color-scheme: dark;
}

.theme-root,
.tint-root {
  color: var(--primary-color);
  background-color: var(--primary-bg-color);
}

pre {
  color: var(--primary-color);
}

.wide-page-wrapper,
.wide-page-wrapper + footer {
  --max-width: var(--max-width-wide-page);
}

a,
.btn,
.animated,
.collapsing {
  transition: var(--transition);
}

.btn {
  transition: var(--transition), outline 0s;
}

.btn:focus {
  box-shadow: none;
}

textarea {
  resize: none;
}

.collapse:not(.show) {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.cursor-copy {
  cursor: copy;
}

.noselect {
  user-select: none;
}

.rounded-border {
  border-radius: var(--border-radius);
  border: 1px solid var(--divider-color);
  overflow: hidden;
}

svg.walletconnect-qrcode__image {
  width: inherit;
  height: inherit;
}
