.lux-input-wrapper {
  .accessory-text {
    line-height: var(--input-line-height);
    color: var(--input-accessory-text-color);
    background-color: var(--input-accessory-text-bg-color);
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius);
  }

  &.accessory-left {
    .accessory-text {
      border-right: 0;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .luma-input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  &.accessory-right {
    .accessory-text {
      border-left: 0;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .luma-input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &.solid {
    .luma-input {
      background-color: var(--solid-input-bg-color);
      border-color: var(--solid-input-border-color);

      &:hover {
        background-color: var(--solid-input-hover-bg-color);
      }

      &:focus {
        background-color: var(--input-focus-bg-color);
        border-color: var(--input-focus-border-color);
      }
    }

    .accessory-text {
      border-color: var(--solid-input-bg-color);
      background-color: var(--primary-bg-color);
    }
  }

  textarea.luma-input {
    // This ensures that the input sizing is correct.
    // If you need to put a button next to the textarea together with the
    // label, use flex-end instead of flex-baseline. This will ensure that
    // the button is bottom-aligned.
    // Baseline alignment is unfortunately not supported for textarea
    // since I can't get it to work in Chrome.
    vertical-align: top;
    line-height: var(--input-textarea-line-height);
  }

  &.medium {
    .luma-input,
    .accessory-text {
      font-size: var(--input-font-size);
      height: var(--input-height);
      padding: var(--input-padding);
    }

    textarea.luma-input {
      padding: var(--input-textarea-padding);
      scroll-padding-block: var(--input-textarea-vertical-padding);
    }

    &.round {
      .luma-input,
      .accessory-text {
        border-radius: calc(var(--input-height) / 2);
      }
    }
  }

  &.large {
    .helper-text {
      font-size: var(--large-input-label-font-size);
    }

    .luma-input,
    .accessory-text {
      font-size: var(--large-input-font-size);
      height: var(--large-input-height);
      padding: var(--large-input-padding);
    }

    textarea.luma-input {
      padding: var(--large-input-textarea-padding);
      scroll-padding-block: var(--large-input-textarea-vertical-padding);
    }

    &.round {
      .luma-input,
      .accessory-text {
        border-radius: calc(var(--large-input-height) / 2);
      }
    }
  }
}
